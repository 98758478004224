


















import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

export default Vue.extend({
  computed: {
    ...mapGetters({
      findByVanity: 'profiles/FindByVanity',
      findPage: 'profiles/pages/Slug',
    }),
    profile(): ApiResource.Profile {
      return this.findByVanity(this.$route.params.VANITY);
    },
    page(): ApiResource.Profile.Page | undefined {
      return this.findPage(this.profile.id, this.$route.params.slug);
    },
    renderMarkdownUrl(): string | undefined {
      if (!this.page) return undefined;
      return `${process.env.VUE_APP_API_URL}/web-api/RenderProfileMarkdownPageSandbox.html?id=${this.page.id}`;
    },
    markdownIframe(): HTMLIFrameElement | undefined {
      if (this.page?.type === 'markdown') return (this.$refs.iframe as HTMLIFrameElement);
      return undefined;
    },
  },
  created() {
    if (!this.page) this.$router.replace({ name: 'profile' });
    else if (this.page?.type === 'link') this.$router.replace({ name: 'external-link', query: { url: this.page.link } });
    else window.addEventListener('message', this.onMessageHandler);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessageHandler);
  },
  methods: {
    onMessageHandler(ev: MessageEvent): void {
      if (!ev.isTrusted || ev.origin !== process.env.VUE_APP_API_URL) return;
      if (ev.data.href !== this.renderMarkdownUrl) return;
      if (this.markdownIframe && ev.data.height) {
        this.markdownIframe.style.height = `${parseInt(ev.data.height, 10)}px`;
      }
    },
  },
  meta() {
    const t = (this.page as ApiResource.Profile.Page | undefined)?.title;

    return {
      title: t,
    };
  },
});
